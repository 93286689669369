import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useFilters, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table'
import PropTypes from 'prop-types';
import BTable from 'react-bootstrap/Table';
import {CSVLink} from 'react-csv';

import { DateRangePicker, Stack } from 'rsuite';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';

// Components
import Alert from '../layout/Alert';

//Styles
import styles from './ReportTable.module.css';
import 'rsuite/dist/rsuite.min.css';

const predefinedRanges = 
[ { label: 'Today'      , value: [new Date(), new Date()]                                                         , placement: 'left' }
, { label: 'Yesterday'  , value: [addDays(new Date(), -1), addDays(new Date(), -1)]                               , placement: 'left' }
, { label: 'This week'  , value: [startOfWeek(new Date()), endOfWeek(new Date())]                                 , placement: 'left' }
, { label: 'Last week'  ,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    placement: 'left'
  }
, { label: 'This month' , value: [startOfMonth(new Date()), new Date()]                                           , placement: 'left' }
, { label: 'Last month' , value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))] , placement: 'left' }
];

const defaultDates = [new Date(), new Date()]; // today

const ReportTable = ({header, columns, reportName, refreshDataFunc}) => {
  const [csvTable, setCsvTable] = useState('');
  const [data, setData] = useState([]); // holds the most recient report data (records)
  const [ alerts, setAlerts ] = useState([]);

  useEffect(() => {
    fetchDataForDates(defaultDates);
    // eslint-disable-next-line
  }, [refreshDataFunc]);

  const fetchDataForDates = async (dateInfo) => {
    if (!dateInfo) {
      setData([]);
      return;
    }
    const startDate = moment(dateInfo[0]).format('YYYY-MM-DD');
    const endDate   = moment(dateInfo[1]).add(1, 'd').format('YYYY-MM-DD');
    const result = await refreshDataFunc(startDate, endDate);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 6000);
      return;
    }

    setData(result.data);
  };

  const formatCSV = (reportName) => {
    // subdomain available when email='tech.ops@cozera.io' and subdomain='cozera'
    const has_subdomain = (data?.length && 'subdomain' in data[0]);

    // Maps the report data from the server to the CSV output
    let dataMap = [];
    switch (reportName) {
      case 'agentEnrollAndFirstAuth':
        dataMap =
        [ {date: 'createdDtm'}
        , {mobileNumber: 'loginValue'}
        , {agent: 'initiatedBy'}
        , {activity: 'activityType'}
        ];
        break;

      case 'activations':
        dataMap =
        [ {date: 'createdDtm'}
        , {mobileNumber: 'phoneNumber'}
        , {fullNname: 'fullName'} 
        , {platform: 'platform'}
        , {deviceCheck: 'vp_device_check'}
        , {member: 'vp_member'}
        , {DL: 'vp_dl'}
        , {approved: 'vp_approved'}
        , {approvedBy: 'vp_approved_agent_name'}
        , {authorizedBy: 'vp_auth_agent_name'}
        ];
        if (has_subdomain) {
          dataMap.push({subdomain: 'subdomain'});
          dataMap.push({coziId: 'cozi_id'});
          dataMap.push({parsedAddress: 'parsedAddress'});
          dataMap.push({correctedAddress: 'correctedAddress'});
        }
        break;

      case 'enrollmentActivity':
        dataMap =
        [ {date: 'createdDtm'}
        , {mobileNumber: 'loginValue'}
        , {agent: 'createdBy'}
        , {activity: 'proofDisplayName'}
        ];
        break;

      case 'authentications':
        dataMap =
        [ {date: 'createdDtm'}
        , {mobileNumber: 'phoneNumber'}
        , {agent: 'agentName'}
        , {type: 'verificationType'}
        , {result: 'status'}
        , {fullName: 'fullName'}
        ];
        break;

      case 'pendingEnrollments':
        dataMap =
        [ {mobileNumber: 'mobileNumber'}
        , {activity: 'verification'}
        , {date: 'createdDtm'}
        ];
        break;

      default:
    }

    // build the header row
    let rows = [];
    rows.push(dataMap.map( (col) => { return Object.keys(col) } ));

    // add the rows
    if (data?.length) {
      for (const record of data) {
        let row = [];
        for (const col of dataMap) {
          const key = Object.values(col);
          if (reportName==='activations' && key==='cozi_id' && !has_subdomain) continue;
          row.push(`${record[key]}`);
        }
        rows.push(row);
      }
    }
    
    setCsvTable(rows);
  };

  function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <div className="row">
          <div className="col-lg-12">
          <input className="form-control-reports"
            value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`General Search: ${count} ${header}...`}
          />
          </div>
      </div>
    )
  };

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <input className="form-control"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} ${header}...`}
      />
    )
  };

  // Our table component
  function Table({ columns, data}) {
    data = data || [];
    const filterTypes = React.useMemo(
      () => ({
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    )

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      //visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 15},
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination,
    )

    const generateSortingIndicator = column => {
      return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    return (
      <>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        
        <BTable striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </BTable>
        <br />
        <div className="row pagination">
            <div className="col-lg-4 paginationFooterLeft">
              <button  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                &#171;
              </button>
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                &#8249;
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                &#8250;
              </button>
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                &#187;
              </button>
            </div>
          <div className="col-lg-4 paginationFooterMid">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
          </div>
          <div className="col-lg-4 paginationFooterRight">
            <span>
              Go to page:{' '}
              <input className="paginationGoToPage"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  let page = e.target.value ? Number(e.target.value) - 1 : 0
                  if (page >= pageOptions.length){
                    e.target.value = pageOptions.length;
                    page = pageOptions.length;
                  }
                  if (page < 0){
                    e.target.value = 1;
                  }
                  gotoPage(page)
                }}
              />
            </span>
            <select className="form-select-page"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {[15, 30, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
            </select>
          </div>
      </div>
      </>
    )
  };

  return (
    <>
      <div className={styles.reportHeader}>
        <Alert alerts={alerts} />
        <div className={styles.reportTitle}>
          {header}
        </div>

        <div className={styles.reportDateRange}>
          <Stack direction="column" spacing={8} alignItems="flex-start">
            <DateRangePicker
              ranges={predefinedRanges}
              showOneCalendar
              placeholder="Select Date Range"
              defaultCalendarValue={defaultDates}
              defaultValue={defaultDates}
              style={{ width: 300 }}
              onShortcutClick = {(shortcut, _event) => { fetchDataForDates(shortcut?.value) }}
              onOk            = {(shortcut, _event) => { fetchDataForDates(shortcut) }}
              onClean         = {(shortcut, _event) => { fetchDataForDates() }}
            />
          </Stack>
        </div>

        <div className={styles.reportButtons}>
          <CSVLink
            className="btn btn-primary"
            style={{ width: "140px" }}
            data={csvTable} 
            asyncOnClick={true}
            onClick={()=>formatCSV(reportName)}
            filename={`${reportName}.csv`}
          >Export to CSV
          </CSVLink>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <Table className={styles.report} columns={columns} data={data} />
      </div>
    </>
  )
};

ReportTable.propTypes = {
  columns: PropTypes.array.isRequired,
};

export { ReportTable };